import React, { useEffect, useState } from "react";
import { CheckoutStyled } from "./styled";
import { PagesBg } from "../../components/Styled/AllImages";
import { Col, Container, Row } from "react-bootstrap";
import CheckoutForm from "./CheckoutForm";
import { Box, MainHeading } from "../../components/Styled/Elements";
import OrderSummary from "./OrderSummary";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useGetProductDetailsQuery } from "../../store/services/productsApi";
import { useGetPackageDetailsQuery } from "../../store/services/packagesApi";
import { useGetSettingsQuery } from "../../store/services/settingsApi";
import { formateNum } from "../../components/Styled/CustomElements";

const Checkout = () => {
  const [packageDetails, setPackageDetails] = useState({});
  const [productDetails, setProductDetails] = useState({});
  const [amountSummary, setAmountSummary] = useState({});
  const [settings, setSettings] = useState({});
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");
  const packageId = searchParams.get("packageId");
  const stateData = location.state;

  const {
    data: productData,
    isLoading: isProductLoading,
    isFetching: isProductFetching,
    isError: isProductError,
    error: productError,
    refetch: refetchProduct,
  } = useGetProductDetailsQuery({ id: productId, lang: language });
  const {
    data: packageData,
    isLoading: isPackageLoading,
    isFetching: isPackageFetching,
    isError: isPackageError,
    error: packageError,
    refetch: refetchPackage,
  } = useGetPackageDetailsQuery({ id: packageId, lang: language });
  const {
    data: settingsData,
    isLoading: isSettingsLoading,
    isFetching: isSettingsFetching,
    isError: isSettingsError,
    error: settingsError,
    refetch: refetchSettings,
  } = useGetSettingsQuery(language);

  useEffect(() => {
    if (packageDetails && productDetails) {
      const subTotalVal = parseFloat(packageDetails?.price) + parseFloat(productDetails?.price)
      setAmountSummary(prevData => ({
        ...prevData,
        sub_total: formateNum(subTotalVal)
      }));
    } else if (productDetails) {
      setAmountSummary(prevData => ({
        ...prevData,
        sub_total: formateNum(productDetails?.price)
      }));
    }
  }, [packageDetails, productDetails]);
  useEffect(() => {
    if (settings && amountSummary && amountSummary.sub_total) {
      
      const vat = parseFloat(settings?.vat);
      const taxVal = (vat / 100) * parseFloat(amountSummary?.sub_total);
      const totalVal = taxVal + parseFloat(amountSummary?.sub_total) + parseFloat(settings?.delivery_charges)
      setAmountSummary(prevData => ({
        ...prevData,
        vat: formateNum(taxVal),
        grand_total: formateNum(totalVal),
        delivery_charges: formateNum(settings?.delivery_charges)
      }));
    }
  }, [settings, amountSummary?.sub_total]);

  useEffect(() => {
    if (productId) {
      refetchProduct();
    }
    if (packageId) {
      refetchPackage();
    }
  }, [productId, packageId, language]);
  useEffect(() => {
    if (productData) {
      setProductDetails(productData?.data);
    }
    if (packageData) {
      setPackageDetails(packageData?.data);
    }
    if (settingsData?.data) {
      setSettings(settingsData?.data);
    }
  }, [productData, packageData, settingsData]);

  return (
    <CheckoutStyled  className="innerPagesBg"
    // style={{ backgroundImage: `url(${PagesBg})` }}
    >
      <Container className="common-container">
        <Box className="checkout-head text-center">
          <MainHeading
            className="lightOrange_gradient checkout-heading"
            size="48px"
            weight="bolder"
          >
            {t("secure_checkout")}
          </MainHeading>
        </Box>
        <Row className="pt-4 py-5 checkout-box">
          <Col lg={6} md={12} className="checkout-Us">
            <Box>
              <CheckoutForm
                stateData={stateData}
                packageId={packageId}
                productId={productId}
                amountSummary={amountSummary}
              />
            </Box>
          </Col>
          <Col lg={6} md={12}>
            <Box>
              <OrderSummary
                packageId={packageId}
                productId={productId}
                productDetails={productDetails}
                packageDetails={packageDetails}
                settingsData={settings}
                amountSummary={amountSummary}
              />
            </Box>
          </Col>
        </Row>
      </Container>
    </CheckoutStyled>
  );
};

export default Checkout;

import styled from "styled-components";

export const ProductsStyled = styled.div`
  // height: 100%;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
`;
export const ProductDetailsStyled = styled.div`
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;

  .product-dtl-section {
    padding-bottom: 4rem;
    border-bottom: 1px solid var(--secBorderColor);
  }

  .key-points {
    border-bottom: 1px solid var(--borderColor);
  }

  /* .checkout-btn {
    margin: 0 1rem 0 1rem;
  } */

  @media (max-width: 576px) {
    .checkout_container{
      flex-wrap: wrap;
    }
  }
`;

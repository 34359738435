import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Cookies from "js-cookie";
import { LazyImage } from "../components/Styled/Elements";
import { Arabic, English } from "../components/Styled/AllImages";

const LangChangeButton = () => {
	const { i18n } = useTranslation();
	const [isEnglishSelected, setIsEnglishSelected] = useState(
		Cookies.get("lang") === "en" ? false : true
	);

	const changeLanguageAndDirection = (lng, dir) => {
		i18n.changeLanguage(lng);
		document.documentElement.dir = dir;
		document.body.classList.toggle("rtl", dir === "rtl");
		Cookies.set("lang", lng);
		Cookies.set("dir", dir);
	};
	const handleLanguageChange = (lng, dir) => {
		changeLanguageAndDirection(lng, dir);
		setIsEnglishSelected(!isEnglishSelected);
		Cookies.set("lang", lng, { expires: 60, path: "/" });
		Cookies.set("dir", dir, { expires: 60, path: "/" });
	};
	// useEffect(() => {
	//   const savedLang = Cookies.get("lang");
	//   const savedDir = Cookies.get("dir");

	//   // Always set the language to Arabic when the project is opened
	//   i18n.changeLanguage("ar");
	//   Cookies.set("lang", "ar", { expires: 60, path: "/" });

	//   if (savedDir) {
	//     document.documentElement.dir = savedDir;
	//     document.body.classList.toggle("rtl", savedDir === "rtl");
	//   } else {
	//     // Set default direction to RTL if not saved in cookies
	//     document.documentElement.dir = "rtl";
	//     document.body.classList.toggle("rtl", true);
	//     Cookies.set("dir", "rtl", { expires: 60, path: "/" });
	//   }
	// }, []);

	useEffect(() => {
		const savedLang = Cookies.get("lang");
		const savedDir = Cookies.get("dir");
		if (savedLang) {
			i18n.changeLanguage(savedLang);
		}
		else {
			// Set default language to Arabic if not saved in cookies
			i18n.changeLanguage("ar");
			Cookies.set("lang", "ar", { expires: 60, path: "/" });
		}
		if (savedDir) {
			document.documentElement.dir = savedDir;
			document.body.classList.toggle("rtl", savedDir === "rtl");
		}
		else {
			// Set default direction to RTL if not saved in cookies
			document.documentElement.dir = "rtl";
			document.body.classList.toggle("rtl", true);
			Cookies.set("dir", "rtl", { expires: 60, path: "/" });
		}
	}, []);

	return (
		<>
			<ButtonBox>
				{isEnglishSelected ? (
					<span
						className="change-Btn"
						onClick={() => handleLanguageChange("en", "ltr")}
					>
						<span>English</span>
						<English className="english_icon" alt="USA" />
					</span>
				) : (
					<span
						className="change-Btn"
						onClick={() => handleLanguageChange("ar", "rtl")}
					>
						<span>العربية</span>
						<Arabic className="arabic_icon" alt="Arabic" />
					</span>
				)}
			</ButtonBox>
		</>
	);
};
const ButtonBox = styled.div`
  padding: 0 0px;
  cursor: pointer;
  z-index: 1;
  .change-Btn {
    /* display: flex;
    align-items: center;
    padding: 5px 7px 5px 10px;
    height: 44px;
    border-radius: 0;
	background-color: var(--white); */
	background: var(--darkColor);
    border: none;
    border-bottom: 4px solid var(--border);
    border-radius: 0px;
    color: var(--white);
    display: flex;
	align-items: center;
    font-size: 16px;
	height: 48px;
    padding: 0px 14px;
    position: relative;
    text-transform: uppercase;
    svg{
        width: 26px;
        height: auto;
        margin-left: 6px;
    }
    span{
      padding-bottom: 0;
      font-family: var(--arBold);
    }
	&:hover{
		background: var(--hoverBg);
	}
    
  }
  .english_icon {
    cursor: pointer;
    z-index: 1;
  }
  .arabic_icon {
    cursor: pointer;
    z-index: 1;
  }
`;

export default LangChangeButton;

import React, { useEffect } from "react";
import { AboutWrapper, BannerWrapper } from "./Styled";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Box,
  HeadingStyled,
  LazyImage,
  MainHeading,
  SubHeading,
  TextStyled,
} from "../../Styled/Elements";
import {
  AboutBg,
  AboutImg1,
  AboutImg2,
  AboutImg3,
  ArrowRight,
  BannerBg,
  BannerManAr,
  BannerManEn,
  ParallexBg,
  SectionsBg,
  Star1,
  Stripe,
  StripeAr,
} from "../../Styled/AllImages";
import { ContactButton } from "../../Styled/CustomElements";
import { useNavigate } from "react-router-dom";
import VideoSlider from "./components/VideoSlider";

const Banner = ({videoData}) => {
  // const videos = [
  //   "https://www.tiktok.com/@saqibkhan0489/video/6951333368554327297?lang=en&q=gym&t=1732392191937",
  //   "https://www.tiktok.com/@saqibkhan0489/video/6951333368554327297?lang=en&q=gym&t=1732392191937"
  // ];
  const videos = [
    {
      url: "https://www.tiktok.com/@saqibkhan0489/video/6951333368554327297?lang=en&q=gym&t=1732392191937",
      thumbnail: AboutImg1,
    },
    {
      url: "https://www.tiktok.com/@saqibkhan0489/video/6951333368554327297?lang=en&q=gym&t=1732392191937",
      thumbnail: AboutImg2,
    },
    {
      url: "https://www.tiktok.com/@saqibkhan0489/video/6951333368554327297?lang=en&q=gym&t=1732392191937",
      thumbnail: AboutImg3,
    },
    {
      url: "https://www.tiktok.com/@saqibkhan0489/video/6951333368554327297?lang=en&q=gym&t=1732392191937",
      thumbnail: AboutImg1,
    },
    // Add more videos
  ];
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollY = window.scrollY;
      document.documentElement.style.setProperty("--scrollTop", `${scrollY}px`);
    });
  }, []);

  return (
    <>
      <BannerWrapper className="banner" id="home">
        <Row>
          <Col className="position-relative">
            <Box className="header-main">
              <Container>
                <Box className="bannerContentWrapper">
                  <Box className="banner-content">
                    <img src={BannerManEn} className="bannerMan bannerManEn" />
                    <img src={BannerManAr} className="bannerMan bannerManAr" />
                    <HeadingStyled
                      className="bannerHeading caption-sub-heading"
                      color="var(--white)"
                    >
                      {t("Step_into")}
                    </HeadingStyled>
                    <HeadingStyled
                      className="bannerHeading caption-heading-main"
                      color="var(--darkColor)"
                      lh="1"
                    >
                      {t("Healthier")}
                    </HeadingStyled>
                    <HeadingStyled
                      className="bannerHeading caption-snd-heading"
                      color="var(--white)"
                      lh="1"
                    >
                      {t("Tomorrow")}
                    </HeadingStyled>
                    <ContactButton
                      icon={ArrowRight}
                      label={t("FindYourPackages")}
                      onClick={() => navigate("/our-packages")}
                      
                    />
                  </Box>
                </Box>
              </Container>
            </Box>
          </Col>
        </Row>
      </BannerWrapper>
      <AboutWrapper className="container-fluid common-space aboutFluid">
        {/* <Box className='baseLayer baseLayerAbout' style={{ background: `url(${AboutBg})` }}></Box> */}
        <Box className="baseLayer baseLayerAbout">
          <img className="aboutBg" src={SectionsBg} />
          <img className="aboutStripe aboutStripeEn" src={Stripe} />
          <img className="aboutStripe aboutStripeAr" src={StripeAr} />
        </Box>
        <Container>
          <Box className="cmn-text-card row">
            {/* <Col md={12} lg={6} className="aboutImgCol">
              <Box className="aboutImgContainer">
                <Box className="aboutImgTxt">{t("ProfessionalTraining")}</Box>
                <LazyImage className="aboutImg" src={AboutImg1} />
              </Box>
              <Box className="aboutImgContainer">
                <Box className="aboutImgTxt">{t("Motivation")}</Box>
                <LazyImage className="aboutImg" src={AboutImg2} />
              </Box>
              <Box className="aboutImgContainer">
                <Box className="aboutImgTxt">{t("Results")}</Box>
                <LazyImage className="aboutImg" src={AboutImg3} />
              </Box> */}
            <Col md={12} lg={6} className="aboutImgCol">
              <VideoSlider videos={videoData} />
            </Col>
            <Col md={12} lg={6} className="aboutContentCol">
              <SubHeading className="sub-heading">
                {t("YourPartnerin")}
              </SubHeading>
              <MainHeading className="main-heading">
                {t("HealthFitness")}
              </MainHeading>

              <TextStyled className="commonTxt">{t("AboutTxt")}</TextStyled>

              <ContactButton
                icon={ArrowRight}
                label={t("show_packages")}
                clName="centerBtn"
                onClick={() => navigate("/our-packages")}
              />
            </Col>
          </Box>
        </Container>
      </AboutWrapper>
    </>
  );
};

export default Banner;

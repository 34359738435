import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    Box,
    LazyImage,
    MainHeading,
    SubHeading,
    SubHeadingStyled,
    TextStyled,
} from "../../Styled/Elements";
import {
    AboutBg,
    AboutImg,
    AboutImg1,
    AboutImg2,
    AboutImg3,
    AboutImgAr,
    ArrowRight,
    ExpertiseBg,
    Stripe,
} from "../../Styled/AllImages";
import { ContactButton, PackageCard } from "../../Styled/CustomElements";
import { useNavigate } from "react-router-dom";
import { ExpertiseWrapper, PackagesWrapper } from "./Styled";
import { expertiseList, packages, scrollImages } from "../../data/Data";

const Packages = ({ data }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    const settings = {
        dots: true,
        infinite: false,
        // initialSlide: 2,
        slidesToShow: 2,
        swipeToSlide: true,
        autoplay: true,
        speed: 1000,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1099,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <PackagesWrapper
                className="container-fluid common-space packagesFluid sectionsBg"
                id="packages"
            >
                {/* <Box className='baseLayer baseLayerAbout' style={{ background: `url(${AboutBg})` }}></Box> */}
                {/* <Box className='baseLayer baseLayerExpertise'>
                    <img className="expertiseBg" src={ExpertiseBg} />
                </Box> */}
                <Container>
                    <Row className="packagesRow mb-5">
                        <Col md={12}>
                            <SubHeading className="sub-heading">
                                {t("AchieveBest")}
                            </SubHeading>
                            <MainHeading className="main-heading">
                                {t("Our_Packages")}
                            </MainHeading>
                        </Col>
                        <Col lg={12} className="sliderWrapper">
                            {isMobile ? (
                                <Slider {...settings} className="scroll-container">
                                    {data &&
                                        data.length > 0 &&
                                        data.map((item, index) => (
                                            <Box className="slide-item-wrapper" key={index}>
                                                <Box className="slide-item">
                                                    <PackageCard data={item} />
                                                </Box>
                                            </Box>
                                        ))}
                                </Slider>
                            ) : (
                                <Row>
                                    {data &&
                                        data.length > 0 &&
                                        data.map((item, index) => (
                                            <Col className="col-6" key={index}>
                                                <PackageCard data={item} />
                                            </Col>
                                        ))}
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center mt-4">
                            <ContactButton
                                size="14px"
                                icon={ArrowRight}
                                label={t("ViewAllPackages")}
                                onClick={() => navigate("/our-packages")}
                                className="greenBtn"
                            />
                        </Col>
                    </Row>
                </Container>
            </PackagesWrapper>
        </>
    );
};

export default Packages;

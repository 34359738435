import { LazyImage } from "../Styled/Elements";
import {
	AdvancePackage,
	ArrowRight,
	BFExpert1,
	BFExpert2,
	HolisticPackage,
	Instagram,
	IntermediatePackage,
	Linkedin,
	Product1,
	Product2,
	Product3,
	Product4,
	StarterPackage,
	Tiktok,
	Trainer1,
	Trainer2,
	Trainer3,
	Trainer4,
	Trainer5,
	Trainer6,
	Trainer7,
	Trainer8,
	Twitter,
} from "../Styled/AllImages";
import { Image, Shimmer } from "react-shimmer";
import { ContactButton } from "../Styled/CustomElements";

export const NavDataConfig = [
	{
		to: "home",
		label: "Home",
        link: false
	},
	{
		to: "our-packages",
		label: "Packages",
        link: true
	},
	{
		to: "products",
		label: "Products",
        link: true
	},
	{
		to: "trainers",
		label: "Trainers",
        link: true
	},
	{
		to: "testimonials",
		label: "testimonials",
        link: false
	},
	{
		to: "faqs",
		label: "FAQs",
        link: false
	},
	{
		to: "contact-us",
		label: "ContactUs",
        link: true
	},
];
export const PageNavConfig = [
	{
		to: "/",
		label: "Home",
        link: false
	},
	{
		to: "/our-packages",
		label: "Packages",
        link: true
	},
	{
		to: "/products",
		label: "Products",
        link: true
	},
	{
		to: "/trainers",
		label: "Trainers",
        link: true
	},
	{
		to: "/",
		label: "testimonials",
        link: false
	},
	{
		to: "/",
		label: "FAQs",
        link: false
	},
	{
		to: "/contact-us",
		label: "ContactUs",
        link: true
	},
];

export const footerNavs = [
	{
		to: "our-packages",
		label: "Packages",
        link: true
	},
	{
		to: "products",
		label: "Products",
        link: true
	},
	{
		to: "trainers",
		label: "Trainers",
        link: true
	},

	{
		to: "faqs",
		label: "FAQs",
        link: false
	},
	{
		to: "contact-us",
		label: "ContactUs",
        link: true
	},
	{
		to: "term-conditions",
		label: "terms_condition",
        link: true
	},
	{
		to: "privacy-policy",
		label: "privacy_policy",
        link: true
	},
];

export const packages = [
	{
		id: 1,
		heading: "starter_fitness_package",
		duration: 4,
		price: 300,
		btnLink: "/our-packages",
		image: StarterPackage,
	},
	{
		id: 2,
		heading: "intermediate_fitness_package",
		duration: 8,
		price: 600,
		btnLink: "/our-packages",
		image: IntermediatePackage,
	},
	{
		id: 3,
		heading: "advanced_fitness_package",
		duration: 12,
		price: 900,
		btnLink: "/our-packages",
		image: AdvancePackage,
	},
	{
		id: 4,
		heading: "holistic_wellness_package",
		duration: 8,
		price: 1200,
		btnLink: "/our-packages",
		image: HolisticPackage,
	},
];
export const products = [
	{
		id: 1,
		name: "gold_protein_whey",
		price: 300,
		image: Product1,
		btnLabel: "buy_now"
	},
	{
		id: 2,
		name: "protein_whey",
		price: 600,
		image: Product2,
		btnLabel: "buy_now"
	},
	{
		id: 3,
		name: "bca_5000",
		price: 900,
		image: Product3,
		btnLabel: "buy_now"
	},
	{
		id: 4,
		name: "creatine_monohydrate",
		price: 1200,
		image: Product4,
		btnLabel: "buy_now"
	},
];

export const trainersList = [
	{
    id: 1,
		name: "Annette Black",
		designation: "gym_trainer",
		image: Trainer1,
	},
	{
    id: 2,
		name: "Kristin Watson",
		designation: "gym_trainer",
		image: Trainer2,
	},
	{
    id: 3,
		name: "Jane Cooper",
		designation: "gym_trainer",
		image: Trainer3,
	},
	{
    id: 4,
		name: "Wade Warren",
		designation: "gym_trainer",
		image: Trainer4,
	},
	{
    id: 5,
		name: "Floyd Miles",
		designation: "gym_trainer",
		image: Trainer5,
	},
	{
    id: 6,
		name: "Eleanor Pena",
		designation: "gym_trainer",
		image: Trainer6,
	},
	{
    id: 7,
		name: "Ronald Richards",
		designation: "gym_trainer",
		image: Trainer7,
	},
	{
    id: 8,
		name: "Annette Black",
		designation: "gym_trainer",
		image: Trainer8,
	},
];

export const expertiseList = [
    {
        img: BFExpert1,
        slideTitle: 'slideTitle1',

    },
	{
        img: BFExpert2,
        slideTitle: 'slideTitle1',
    },
	{
        img: BFExpert1,
        slideTitle: 'slideTitle1',
    },
	{
        img: BFExpert2,
        slideTitle: 'slideTitle1',
    },
]


export const allFaqs = [
    {
        heading: 'FaqTitle1',
        text: 'FaqText1',
        
    },
    {
        heading: 'FaqTitle2',
        text: 'FaqText1',
        
    },
    {
        heading: 'FaqTitle3',
        text: 'FaqText1',
        
    },
    {
        heading: 'FaqTitle4',
        text: 'FaqText1',
        
    },
	{
        heading: 'FaqTitle5',
        text: 'FaqText1',
        
    },
	{
        heading: 'FaqTitle6',
        text: 'FaqText1',
        
    },
    
]

export const socialIcon = [
    // {
    //     img: <Twitter />,
    //     link: '#!',
    // },

    {
        img: <Instagram />,
        link: 'https://www.instagram.com/ownyou_health/',
    },
    // {
    //     img: <Tiktok />,
    //     link: '#!',
    // },
    // {
    //     img: <Linkedin />,
    //     link: '#!',
    // },
];
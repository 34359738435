import React, { useEffect, useState } from "react";
import { PagesBg } from "../../components/Styled/AllImages";
import { ProductsStyled } from "./styled";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import {
  Box,
  HeadingStyled,
  SubHeadingStyled,
} from "../../components/Styled/Elements";
import { useTranslation } from "react-i18next";
import { ProductCard } from "../../components/Styled/CustomElements";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetHomeDataQuery } from "../../store/services/homepageApi";

const Products = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [productList, setProductList] = useState([])

  const handleClick = (id) => {
    const item = productList.length > 0 && productList.find(x => x.id == id)
    navigate(`/product-details/${id}?product=${item.name}`)
  };

  const {
    data: homeData,
    isError,
    isLoading,
    isFetching,
    error,
  } = useGetHomeDataQuery(i18n.language);

  useEffect(() => {
    if (homeData?.data?.products.length > 0)
      setProductList(homeData?.data?.products);
  }, [homeData]);
  return (
    <ProductsStyled className="innerPagesBg"
    // style={{ backgroundImage: `url(${PagesBg})` }}
    >
      <Container className="common-container">
        <Box>
          <HeadingStyled color="var(--themeColor)" size="20px" weight="500">
            {t("explore_premium_selection")}
          </HeadingStyled>
          <SubHeadingStyled size="36px" weight="600" color="var(--darkColor)">
            {t("our_products")}
          </SubHeadingStyled>
        </Box>
        <Box className="mt-4">
          <Row>
            {isLoading | isFetching ? (
              <Box className="d-flex justify-content-center">
                <Spinner animation="border" className="custom-spinner" />
              </Box>
            ) : (
              productList.length > 0 && productList.map((x, i) => (
                <Col lg={3} md={6} sm={6} className="mt-4 col-6">
                  <ProductCard
                    data={x}
                    t={t}
                    handleClick={handleClick}
                    className={"mt-4"}
                  />
                </Col>
              ))
            )}
          </Row>
        </Box>
      </Container>
    </ProductsStyled>
  );
};

export default Products;

import React, { useState, useRef } from "react";
import styled from "styled-components";
import { FaPlay, FaPause } from "react-icons/fa";
import { PauseIcon, PlayIcon } from "../../../Styled/AllImages";
import { Box } from "../../../Styled/Elements";
import { useTranslation } from "react-i18next";

const StyledAudioPlayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  margin: 0 auto;

  .play-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 10px;
    background: var(--darkColor);
    border: 1px solid var(--secBorderColor);
    padding: 20px;
  }

  .play-pause-btn {
    display: flex;
    align-items: center;
    // background-color: #00ffcc;
    // color: #0b132b;
    // border: none;
    // border-radius: 50%;
    // width: 60px;
    // height: 60px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // cursor: pointer;
    // font-size: 1.5rem;
    // transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .progress-bar {
    width: 100%;
    height: 6px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;

    .progress-filled {
      height: 100%;
      background-color: #00ffcc;
      width: ${(props) => props.progress || 0}%;
    }
  }

  .time-info {
    font-size: 14px;
    color: var(--darkColor);
    margin-top: 10px;
  }
  @media (max-width: 730px) {
    padding: 5px;
  }
  @media (max-width: 575px) {
    .play-container{
      padding: 5px;
      gap: 5px;
    }
    .play-pause-btn svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const AudioPlayer = React.forwardRef(({ audioSrc }, ref) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("0:00");

  const audioRef = useRef(null);

  const togglePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  React.useImperativeHandle(ref, () => ({
    pauseAudio,
  }));

  const handleAudioEnd = () => {
    setIsPlaying(false);
    setProgress(0);
    setCurrentTime("0:00");
  };

  const updateProgress = () => {
    const audio = audioRef.current;
    const current = audio.currentTime;
    const total = audio.duration;

    setProgress((current / total) * 100 || 0);
    setCurrentTime(formatTime(current));
    setDuration(formatTime(total));
  };

  const handleProgressClick = (e) => {
    const progressBar = e.target.getBoundingClientRect();
    const clickX = e.clientX - progressBar.left;
    const progressPercent =
      language === "ar"
        ? (progressBar.right - e.clientX) / progressBar.width
        : clickX / progressBar.width;
    const newTime = progressPercent * audioRef.current.duration;
    // const newTime = (clickX / progressBar.width) * audioRef.current.duration;

    audioRef.current.currentTime = newTime;
    updateProgress();
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <StyledAudioPlayer progress={progress}>
      <div className="play-container">
        <Box className="play-pause-btn" onClick={togglePlayPause}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </Box>
        <div className="progress-bar" onClick={handleProgressClick}>
          <div className="progress-filled" />
        </div>
      </div>
      <div className="time-info">
        {currentTime} / {duration}
      </div>
      <audio
        ref={audioRef}
        src={audioSrc}
        onTimeUpdate={updateProgress}
        onLoadedMetadata={updateProgress}
        onEnded={handleAudioEnd}
      />
    </StyledAudioPlayer>
  );
});

export default AudioPlayer;

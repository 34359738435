import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, LazyImage, MainHeading, SubHeading, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { AboutBg, AboutImg, AboutImg1, AboutImg2, AboutImg3, AboutImgAr, ArrowRight, ExpertiseBg, Stripe, TrainerBg } from '../../Styled/AllImages'
import { ContactButton, TrainerCard } from '../../Styled/CustomElements'
import { useNavigate } from 'react-router-dom'
import { TrainersWrapper } from './Styled'

const Trainers = ({data}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    
    return (
        <>
            <TrainersWrapper className='container-fluid common-space trainersFluid' id='trainers'>
                <Box className='baseLayer baseLayerTrainers'>
                    <img className="trainersBg" src={TrainerBg} />
                </Box>
                <Container>
                    <Row className='trainersRow'>
                        <Col md={12}>
                            <SubHeading className='sub-heading'>{t('meet_our')}</SubHeading>
                            <MainHeading className='main-heading'>{t('expert_trainers')}</MainHeading>
                        </Col>
                        <Col lg={12} className='sliderWrapper'>
                            
                            <Row className='mb-3'>
                                {/* {data && data.length > 0 && data.slice(0, 3).map((x, i) => ( */}
                                {data && data.length > 0 && data.slice(0, 3).map((x, i) => (
                                    <Col lg={4} md={6} sm={12} className="mt-4">
                                        <TrainerCard data={x} t={t} lang={i18n.language} />
                                    </Col>
                                ))}
                            </Row>
                                
                        </Col>
                    </Row>
                    <Row >
                        <Col className='d-flex justify-content-center mt-4'>
                            <ContactButton size="14px" className="greenBtn" icon={ArrowRight} label={t('ViewAllTrainers')} onClick={() => navigate('/trainers')} />
                        </Col>
                    </Row>
                </Container>
            </TrainersWrapper>
        </>
    )
}

export default Trainers

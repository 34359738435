import styled from "styled-components";

export const CheckoutStyled = styled.div`
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 260px;
    border-radius: 32px 32px 0 0;
    background: var(--gradient);
    top: -260px;
    z-index: -2;
  }
  .checkout-heading {
    line-height: 0.9;
  }
  iframe {
    width: 100%;
    height: 532px;
  }
  @media (max-width: 992px) {
    .checkout-box {
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 576px) {
    .checkout-container {
      padding: 6rem 0 5rem 0;
      max-width: 470px;
    }
  }
`;
export const CheckoutFormWrapper = styled.div`
  .input-field::placeholder {
    font-weight: 100;
    font-family: ${(props) =>
      props.lang === "en" ? "var(--regular)" : "var(--regularAr)"};
    color: var(--primaryBg2);
  }
  .loading-btn {
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-family: var(--regular);
  }
  .checkout-social-links {
    position: relative;
    left: 15px;
  }

  input[type="file"]::before {
    position: absolute;
    pointer-events: none;
    top: 19px;
    left: 16px;
    height: 20px;
    width: 20px;
    content: "";
    background-repeat: no-repeat;
  }

  input[type="file"]::after {
    position: absolute;
    pointer-events: none;
    top: 19px;
    left: 40px;
    color: var(--textPalceholder);
    content: "Upload Attechment";
  }
`;

export const OrderSummaryStyled = styled.div`
  .summary-img span {
    width: 117px;
    height: 117px;
  }
  .summary-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .overview-box {
    background: var(--themeColor);
    border: 1px solid var(--primaryBg2);
  }
  .overview-head,
  .overview-details {
    border-bottom: 1px solid var(--primaryBg2);
  }
  .item-price {
    margin: ${(props) => (props.lang === "en" ? "0 0 0 1rem" : "0 1rem 0 0")};
  }
`;

import styled from "styled-components";
import { TrainersBg } from "../../Styled/AllImages";

export const TrainersWrapper = styled.section`
	padding-top: 0;
	height: 100%;
    background-color: var(--white);
    background-image: url(${TrainersBg});
    background-size: auto 140%;
    background-repeat: no-repeat;
    background-position: center;
    /* &.sectionsBg{
        background-size: 100% 100%;
        background-position: 0 -s80px;
    } */
	.trainersBg {
		mix-blend-mode: soft-light;
		width: 100%;
		height: 100%;
		object-fit: cover;
        
        
	}

	
`;

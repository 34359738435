import styled from "styled-components";
import {
  Box,
  HeadingStyled,
  LabelStyled,
  LazyImage,
  Span,
  SpanStyled,
  SubHeadingStyled,
  TextStyled,
} from "./Elements";
import { useTranslation } from "react-i18next";
import { Button, Row, Spinner } from "react-bootstrap";
import { ArrowRight, ArrowRight2, Check2 } from "./AllImages";
import { Link, useNavigate } from "react-router-dom";

const PACKAGE_ASSET_URL = process.env.REACT_APP_PACKAGE_ASSET_URL;
const PRODUCTS_ASSET_URL = process.env.REACT_APP_PRODUCTS_ASSET_URL;
const TRAINERS_ASSET_URL = process.env.REACT_APP_TRAINERS_ASSET_URL;

export const sliceProps = (props, sub) => {
  const { children, ...otherProps } = props;
  sub.forEach((prop) => delete otherProps[prop]);
  return otherProps;
};

export const formateNum = (num) => {
  const val = parseFloat(num);
  if (val !== undefined && val !== null && !isNaN(val)) {
    const numVal = parseFloat(val);
    return numVal.toFixed(2);
  }
  return 0;
};

export const ButtonWithIcon = (props) => {
  const unwantedProps = ["icon", "label", "clName"];
  const buttonProps = props.type ? { href: props.type } : {};
  return (
    <ButtonWrapper
      {...sliceProps(props, unwantedProps)}
      className={`d-flex align-items-center ${props.clName || ""}`}
    >
      <button
        {...buttonProps}
        className={`d-flex align-items-center ${props.className || ""} ${
          !props.icon ? "Btn-icon" : ""
        } `}
        {...buttonProps}
        autoComplete="false"
        onClick={() => {
          if (props.type) {
            window.location.href = props.type;
          }
        }}
      >
        {props.icon && <props.icon width="20px" />}
        <SpanStyled color="var(--white)" size="16px">
          {props.label ? props.label : ""}
        </SpanStyled>
      </button>
    </ButtonWrapper>
  );
};

export const ContactButton = (props) => {
  const unwantedProps = [
    "icon",
    "label",
    "className",
    "clName",
    "onClick",
    "isLoading",
  ];
  const buttonProps = props.type ? { type: props.type } : {};
  return (
    <ContactWrapper
      {...sliceProps(props, unwantedProps)}
      className={`contactWrapper  ${props.clName ? props.clName : ""}`}
      disabled={props.disabled}
    >
      <button
        className={`d-flex align-items-center ${props.className || ""} ${
          !props.icon ? "Btn-icon" : ""
        } `}
        {...buttonProps}
        onClick={props.onClick}
        autoComplete="false"
        // disabled={props.disabled}
      >
        {props.isLoading ? (
          <Spinner variant="success" />
        ) : (
          <>
            <SpanStyled size={props.size ? props.size : "18px"}>
              {props.label ? props.label : ""}
            </SpanStyled>
            {props.icon && <props.icon width="22px" />}
          </>
        )}
      </button>
    </ContactWrapper>
  );
};
export const ButtonIcon2 = (props) => {
  const unwantedProps = ["icon", "label"];
  return (
    <ButtonIconWrap
      className="custom-btn"
      {...sliceProps(props, unwantedProps)}
    >
      <button
        className={`click-btn ${!props.icon ? "Btn-icon" : ""}`}
        autoComplete="false"
      >
        <HeadingStyled
          color="var(--white)"
          transform="math-auto"
          size="14px"
          weight="normal"
        >
          {props.label ? props.label : ""}
        </HeadingStyled>
        {props.icon && <props.icon width="22px" />}
      </button>
    </ButtonIconWrap>
  );
};
export const InputWithIcon = (props) => {
  const unwantedProps = ["icon", "label"];
  return (
    <InputStyledWrap className="position-relative">
      <InputStyled
        {...sliceProps(props, unwantedProps)}
        className={`w-100 ${!props.icon ? "no-icon" : ""}`}
        autoComplete="false"
      />
      {props.icon && (
        <props.icon width="18px" className="position-absolute input-svg" />
      )}
    </InputStyledWrap>
  );
};
export const TextWithIcon = (props) => (
  <TextWithStyledWrapper
    color={props.color}
    className={`d-flex ${
      props.desc ? "align-items-start" : "align-items-center"
    }`}
  >
    <Box className="mx-2 d-flex align-items-center justify-content-center contact-svg">
      <props.icon width="26px" />
    </Box>
    <Box>
      <Box
        className="mb-0 contact-text"
        size={props.headingSize ? props.headingSize : "14px"}
        color={!props.alsotextColor ? "var(--white)" : "var(--white)"}
        dangerouslySetInnerHTML={{ __html: props.title }}
      ></Box>
      {props.desc ? (
        <TextStyled
          className="text-uppercase"
          color={"var(--white)"}
          size="14px"
        >
          {props?.desc}
        </TextStyled>
      ) : null}
    </Box>
  </TextWithStyledWrapper>
);

export const PackageCard = ({ data }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;
  const handleNavigation = (id) => {
    navigate(`/package-details/${id}?package=${data?.name}`);
  };
  return (
    <PackageCardStyled lang={lang}>
      <Box className="package-card mt-4">
        <Box className="pkg-content">
          <Row className="px-2 py-2 px-sm-4 h-100 pkg-bg align-items-center">
            <Box className="pkg-content-container">
              <SubHeadingStyled
                size="24px"
                weight="500"
                color="var(--white)"
                className={`pkg-name ${
                  data?.name && data.name.length > 30 ? "overflow-text" : ""
                }`}
              >
                {data?.name}
              </SubHeadingStyled>
              <Box className="d-inline-block">
                <TextStyled
                  className="duration-text p-2 m-0"
                  size="14px"
                  weight="600"
                  color="var(--white)"
                >
                  {t("duration")}: {data?.duration && parseInt(data.duration)}{" "}
                  {t("weeks")}
                </TextStyled>
              </Box>
              <SubHeadingStyled
                size="28px"
                color="var(--white)"
                weight="600"
                className="pkg-price"
              >
                {t("sar")} {data?.price && data.price}
              </SubHeadingStyled>
              <Box className="w-100 mb-2 d-flex justify-content-md-end">
                <ContactButton
                  icon={ArrowRight}
                  label={t("view_details")}
                  size="14px"
                  onClick={() => handleNavigation(data.id)}
                />
              </Box>
            </Box>
          </Row>
        </Box>
        {data.image && (
          <Box
            className="bg-container"
            style={{
              backgroundImage: `url(${PACKAGE_ASSET_URL}/${data.image})`,
            }}
          />
        )}
      </Box>
    </PackageCardStyled>
  );
};

export const TrainerCard = ({ data, t }) => {
  const navigate = useNavigate();
  return (
    <TrainerCardStyled className="p-2">
      <Box className="d-flex flex-column align-items-center">
        <Box className="trainer-img-container">
          <Box className={"trainer-img"}>
            <LazyImage src={`${TRAINERS_ASSET_URL}/${data.profile_image}`} />
          </Box>
        </Box>
        <HeadingStyled
          size="24px"
          color="var(--darkColor)"
          className="mt-3 text-center"
        >
          {data.name}
        </HeadingStyled>
        <SubHeadingStyled size="16px" weight="">
          {data.job}
        </SubHeadingStyled>
        <ContactButton
          size="14px"
          icon={ArrowRight2}
          label={t("view_details")}
          className="mt-3 greenBtn"
          onClick={() => navigate(`/trainer-details/${data.id}`)}
        />
      </Box>
    </TrainerCardStyled>
  );
};
export const ProductCard = ({
  data,
  t,
  handleClick,
  btnDisabled,
  selectedProduct,
  className,
  quantity,
}) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const onClick = () => {
    handleClick(data.id);
  };
  return (
    <ProductCardStyled className={`${className}`} onClick={onClick}>
      <Box className="w-100">
        <Box className="d-flex flex-column align-items-center text-center product-card py-4">
          <Box className="product-img-container">
            {data.main_image && (
              <LazyImage
                src={`${PRODUCTS_ASSET_URL}/${data.main_image}`}
                className="product-img"
              />
            )}
          </Box>
          <HeadingStyled className="product-name" size="16px">
            {data?.name}
          </HeadingStyled>
          <SubHeadingStyled size="16px" color="var(--white)" weight="">
            {t("sar")} {data?.price && data?.price}
          </SubHeadingStyled>
          <ContactButton
            size="14px"
            icon={selectedProduct === data.id ? Check2 : ArrowRight2}
            label={t(
              selectedProduct === data.id
                ? "added"
                : quantity < 1
                ? "out_of_stock"
                : "buy_now"
            )}
            type="button"
            onClick={onClick}
            disabled={btnDisabled || quantity < 1}
          />
        </Box>
      </Box>
    </ProductCardStyled>
  );
};

export const NumberInput = (props) => {
  const unwantedProps = [
    "className",
    "label",
    "handleDecrement",
    "handleIncrement",
  ];
  return (
    <NumberInputStyled>
      <LabelStyled size="16px" color="var(--themeColor)" className="mb-3">
        {props.label}
      </LabelStyled>
      <Box className="number-input mb-1">
        <button
          className="num-btn px-4 pt-1 pb-2"
          onClick={props.handleDecrement}
        >
          -
        </button>
        <input
          type="number"
          min="0"
          className={`input-fld ${props.className}`}
          {...sliceProps(props, unwantedProps)}
        />
        <button
          className="num-btn px-4 pt-1 pb-2"
          onClick={props.handleIncrement}
        >
          +
        </button>
      </Box>
    </NumberInputStyled>
  );
};

const TextWithStyledWrapper = styled.div`
  svg {
    [stroke] {
      stroke: ${({ color }) => (color ? color : "")};
    }
  }
`;
export const InputStyled = styled.input`
  background: var(--bgColor);
  border: none;
  border-radius: 8px;
  height: 43px;
  // color: var(--textGrey);
  &:not(.no-icon) {
    padding-left: 50px;
  }
  &.no-icon {
    padding-left: 18px;
  }
  padding-right: 18px;
  outline: none;
  &,
  &::placeholder {
    font-size: 14px;
    color: var(--textPalceholder);
  }
`;
export const InputStyledWrap = styled.div`
  border-radius: 8px;
  background: var(--white);
  svg {
    left: 13px;
    top: 12px;
    height: 20px;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  padding-top: 20px;
  width: fit-content;
  button,
  button::after {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  button {
    background: ${(props) => (props.bg ? props.bg : "var(--darkColor)")};
    border: none;
    border-bottom: 4px solid var(--border);
    border-radius: 0px;
    color: var(--white);
    display: flex;
    gap: 12px;
    height: 54px;
    padding: 0px 20px;
    position: relative;
    span {
      font-family: var(--lightMedium);
      color: ${(props) => (props.color ? props.color : "var(--white)")};
    }
    
    & svg {
      height: 22px;
      [fill] {
        fill: var(--white);
      }
    }
    &:hover {
      background: var(--hoverBg);
    }
    ${(props) =>
      props.disabled &&
      `
        background: var(--darkColorHover);
        color: var(--textSecondary);
        border-bottom-color: var(--disabledBorder);
        
        span {
          color: var(--textSecondary);
        }

        & svg [fill] {
          fill: var(--textSecondary);
        }

        &:hover {
          background: var(--darkColorHover);
        }
      `}
  }
  button.greenBtn {
        background: ${(props) => (props.bg ? props.bg : "var(--themeColor)")};
        span {
            color: ${(props) => (props.color ? props.color : "var(--darkColor)")};
        }
        & svg {
            [fill] {
                fill: var(--darkColor);
            }
        }
        &:hover {
            background: var(--hoverBg);
        }
    }
  @media (max-width: 470px) {
    button {
      gap: 5px;
      padding: 0 10px;
      span {
        font-size: 12px;
      }
    }
  }
`;
export const ButtonIconWrap = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.content ? props.content : "")};
    gap: 6px;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    background: transparent;
    border: 1px solid white;
    color: var(--white);
    text-transform: Uppercase;
    cursor: pointer;
    // transform: scale(0.9);
    transition: all ease-in-out 0.4s;
    margin: 15px 0;
    &:hover {
      // transform: scale(1);
      background: var(--darkGradient);
      border: 1px solid var(--themeColor);
    }
  }
`;
export const ButtonWrapper = styled.div`
  button,
  button::after {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }

  button {
    background: var(--themeColor);
    border: none;
    border-bottom: 4px solid var(--border);
    border-radius: 0px;
    color: var(--darkColor);
    display: flex;
    gap: 8px;
    height: 48px;
    padding: 0px 14px;
    position: relative;
    text-transform: uppercase;
    span {
      font-family: var(--regular);
    }
    & svg {
      [fill] {
        fill: var(--white);
      }
    }
  }

  /* button::before,
button::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--darkGradient);
}
button::before{
    background: transparent;
} */

  button:hover {
    background: var(--hoverBg);

    /* & svg{
        [fill]{
            fill:var(--white);
        }
        [stroke]{
            stroke:var(--white);
        }
    } */
  }
`;
export const TextButton = (props) => {
  const unwantedProps = ["icon", "label", "bgBtn", "clName", "clNameMain"];
  return (
    <TextButtonWrapper
      {...sliceProps(props, unwantedProps)}
      className={`d-flex align-items-center btn-text ${
        props.clNameMain ? props.clNameMain : ""
      }`}
    >
      <button
        className={` ${props.clName ? props.clName : ""} ${
          props.bgBtn ? props.bgBtn : ""
        } ${!props.icon ? "Btn-icon" : ""} `}
        autoComplete="false"
      >
        {props.icon && <props.icon width="28px" />}
        <SpanStyled dir="ltr" size="16px">
          {props.label ? props.label : ""}
        </SpanStyled>
      </button>
    </TextButtonWrapper>
  );
};
const TextButtonWrapper = styled.div`
  .btn-text {
    background: var(--gradient);
    padding: 8px 12px;
    border-radius: 8px;
  }
  & button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 6px;

    & span {
      color: var(--white);
      font-family: var(--arRegular);
    }
  }
`;

export const PackageCardStyled = styled.div`
  .package-card {
    position: relative;
    height: 260px;
  }
  .pkg-name {
    height: 72px;
  }
  .overflow-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    white-space: normal;
  }
  .pkg-content {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        262.78deg,
        rgba(38, 205, 159, 0) 0%,
        rgba(27, 195, 147, 0.5) 30%,
        rgba(19, 103, 80, 0.9) 100%
      );

      z-index: -1;
      body.rtl & {
        /* background: linear-gradient(
          262.78deg,
          #12233d 0%,
          rgba(38, 205, 159, 0) 99.35%
        ); */
        background: linear-gradient(
            262.78deg,
            rgba(19, 103, 80, 0.9) 0%,
            rgba(27, 195, 147, 0.5) 70%,
            rgba(38, 205, 159, 0) 100%
        );
      }
    }
  }
  .pkg-content-container {
    z-index: 9;
  }
  .duration-text {
    display: inline-block;
    background-color: rgba(38, 205, 159, 0.8);
  }
  .bg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid var(--secBorderColor);
    transform: ${(props) => (props.lang === "ar" ? "scaleX(-1)" : "scaleX(1)")};
  }
  @media (max-width: 470px) {
    .pkg-name {
      height: 96px;
    }
    .duration-text {
      font-size: 12px;
    }
    .pkg-price {
      font-size: 20px;
    }
  }
`;
export const ProductCardStyled = styled.div`
  height: 367px;
  display: flex;
  align-items: end;
  cursor: pointer;
  .product-card {
    padding-left: 10px;
    padding-right: 10px;
    /* background: radial-gradient(
      54.48% 58.56% at 57.57% 43.76%,
      #114244 0%,
      #12233d 100%
    ) !important; */
    background: var(--themeColor) !important;
    border: 1px solid var(--secBorderColor);
  }
  .product-name {
    height: 50px;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: -moz-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    color: var(--darkColor);
  }
  .product-img-container {
    position: relative;
    width: 100%;
    height: 100px;
  }
  .product-img-container span {
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    width: 100%;
    height: 206px;
    display: flex !important;
    justify-content: center;
  }
  .product-img-container img {
    width: 235px;
    height: 100%;
    object-fit: contain;
  }
`;
const TrainerCardStyled = styled.div`
  width: 100%;
  height: 100%;
  .trainer-img-container {
    height: 290px;
    width: 290px;
  }
  .trainer-img {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      height: 100%;
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: radial-gradient(
        84.14% 84.14% at 54.66% 15.86%,
        rgba(38, 205, 159, 0) 0%,
        #26cd9f 100%
      );
      z-index: 1;
    }
  }
`;
const NumberInputStyled = styled.div`
  .number-input {
    display: flex;
    align-items: center;
    background-color: var(--inputBg);
    border-top: 1px solid var(--borderColor);
    border-bottom: 1px solid var(--borderColor);
    width: fit-content;
  }
  .input-fld {
    padding: 10px 15px;
    max-width: 100px;
    border: none;
    outline: none;
    background: transparent;
    caret-color: var(--themeColor);
    text-align: center;
    border: none;
    color: var(--themeColor);
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  .num-btn {
    height: 100%;
    width: 100%;
    background-color: transparent;
    color: #00f5a0;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    border-right: 1px solid var(--borderColor);
    border-left: 1px solid var(--borderColor);
  }

  .num-btn:focus {
    outline: none;
  }

  input:focus {
    outline: none;
  }
`;

import styled from "styled-components";

export const ProductsStyled = styled.div`
  height: 100%;
  background-color: var(--white);
  & > .sectionsBg:first-child{
    background-position: 0px -150px;
    background-size: 100% 100%;
  }
`;

export const MoveOnWrapper = styled.div`
	padding-top: 110px;
	padding-bottom: 40px;
	.baseLayerMoveOn{
		top: -350px;
	}
	&.sectionsBg{
        background-size: 100% 100%;
        background-position: 0 -80px;
    }
	.moveOnBg {
		mix-blend-mode: soft-light;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.moveOnWrapper{
		position: relative;
		padding: 90px 100px 95px 150px;
		.moveOnImg{
			position: absolute;
			right: 60px;
			bottom: 0;
			width: 690px;
			height: auto;
		}
		button {
			&:hover{
				background-color: var(--darkColorHover);
			}
			& svg{
				[fill]{
					fill:var(--themeColor);
				}
			}
			
		}
	}
	.moveSubHeading{
		display: flex;
		width: 100%;
		font-family: var(--bold1);
		color: var(--darkColor);
		font-size: 35px;
	}
	.moveMainHeading{
		display: flex;
		width: 100%;
		font-family: var(--bold2);
		color: var(--darkColor);
		font-size: 65px;
		line-height: 65px;
	}
	@media (max-width: 1399px){
		.moveOnWrapper{
			padding: 90px 50px 95px 90px;
			.moveOnImg{
				right: 30px;
			}
		}
	}
	@media (max-width: 1199px){
		.moveOnWrapper{
			padding: 90px 50px 95px 50px;
			.moveOnImg{
				right: -10px;
				width: 660px;
			}
			.moveMainHeading {
				font-size: 60px;
			}
		}
	}
	@media (max-width: 991px){
		padding-top: 120px;
		.moveOnWrapper{
			padding: 30px 50px 45px 20px;
			.moveOnImg{
				right: -10px;
				width: 460px;
			}
			.moveMainHeading {
				font-size: 50px;
				line-height: 45px;
			}
			.moveSubHeading {
				font-size: 30px;
			}
		}
	}
	@media (max-width: 767px){
		padding-top: 70px;
		.moveOnWrapper{
			padding: 30px 20px 40px 20px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			& > *{
				justify-content: center;
				text-align: center;
			}
			.moveOnImg{
				display: none;
			}
			.moveMainHeading {
				font-size: 40px;
				line-height: 40px;
			}
			.moveSubHeading {
				font-size: 25px;
			}
		}
	}
	
`;
import { Col, Container, Row } from "react-bootstrap";
import {
  Box,
  HeadingStyled,
  LazyImage,
  SubHeadingStyled,
  TextStyled,
} from "../../components/Styled/Elements";
import { TrainersStyled } from "./styled";
import { PagesBg } from "../../components/Styled/AllImages";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TrainerCard } from "../../components/Styled/CustomElements";
import { useEffect, useState } from "react";
import { useGetHomeSectionTwoQuery } from "../../store/services/homepageApi"

const Trainer = () => {
  const { t, i18n } = useTranslation();
  const [trainerList, setTrainerList] = useState([])
  const lang = i18n.language;

  const {
    data: homeTwoData,
    isError: isHomeTwoError,
    isLoading: isHomeTwoLoading,
    isFetching: isHomeTwoFetching,
    error: homeTwoError,
  } = useGetHomeSectionTwoQuery(i18n.language);

  useEffect(() => {
    if (homeTwoData?.data?.trainers.length > 0)
      setTrainerList(homeTwoData?.data?.trainers);
  }, [homeTwoData]);
  return (
    <>
      <TrainersStyled className="innerPagesBg"
        lang={lang}
        // style={{ backgroundImage: `url(${PagesBg})` }}
      >
        <Container className="common-container">
          <Box>
            <HeadingStyled color="var(--themeColor)" size="20px" weight="500">
              {t("meet_our")}
            </HeadingStyled>
            <SubHeadingStyled size="36px" weight="600" color="var(--darkColor)">
              {t("expert_trainers")}
            </SubHeadingStyled>
          </Box>
          <Box className="my-4">
            <Row>
                {trainerList.length > 0 && trainerList.map((x, i) => (
                    <Col lg={4} md={6} sm={12} className="mt-5">
                        <TrainerCard data={x} t={t} />
                    </Col>
                ))}
            </Row>
          </Box>
        </Container>
      </TrainersStyled>
    </>
  );
};

export default Trainer;

import styled from "styled-components";

export const PackagesWrapper = styled.section`
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  .packages-container {
    padding: 10rem 0 5rem 0;
  }
  .slick-slide > div {
    padding: 0 5px;
  }
`;

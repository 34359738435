import React from "react";
import { ContactUsWrapper } from "./styled";
import { Col, Container, Row } from "react-bootstrap";
import { Box } from "../../components/Styled/Elements";
import ContactForm from "./ContactForm";
import { useTranslation } from "react-i18next";
import { PagesBg } from "../../components/Styled/AllImages";

const Contact = () => {
  const { t } = useTranslation();
  const Map =
    "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3623.4169790408796!2d46.72135527536719!3d24.746888478003537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQ0JzQ4LjgiTiA0NsKwNDMnMjYuMiJF!5e0!3m2!1sen!2ssa!4v1720088483033!5m2!1sen!2ssa";

  return (
    <ContactUsWrapper id="contact"  className="innerPagesBg"
    // style={{ backgroundImage: `url(${PagesBg})` }}
    >
      <Container className="common-container">
        <Row className="pt-4 py-5 contact-box">
          {/* <Col lg={6} md={12}>
            <Box className="contect-with-you">
              <Box className="contact-box">
                <iframe
                  src={Map}
                  allowfullscreen=""
                  loading="lazy"
                  title="location"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </Box>
            </Box>
          </Col> */}
          <Col lg={6} md={12} className="contact-Us">
            <Box className="contect-with-us">
              <ContactForm />
            </Box>
          </Col>
        </Row>
      </Container>
    </ContactUsWrapper>
  );
};

export default Contact;

import styled from "styled-components";

export const PaymentStatusStyled = styled.div`
  // height: 100%;
  // background-size: cover !important;
  // background-repeat: no-repeat !important;
  // background-position: center !important;
  .payment-info-card {
    background: var(--themeColor);
    border: 1px solid var(--themeColor);
  }
  .home-btn {
    color: var(--darkColor);
  }
  .home-btn svg {
    width: 16px;
    height: 16px;
  }
  .home-btn path {
    fill: var(--darkColor);
  }

`;
